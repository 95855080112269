// Color Variant

$whiteColor: #ffffff;
$primaryColorLight: #5a8c24;
$primaryColorDarkmode: #212533;
$DarkBackground: #21282e;
$DarkBackgroundSelect: #3c4452;
$darkBodyColor: #161c24;
$lightBodyColor: #f3f4f6;
$redColor: #B7281B;
$timeLineBlueColor: #0077c0;
$borderRadius: 10px;
$boxShadow: 10px 10px 50px rgba(0, 0, 0, 0.06);
$DarkModeColor: rgba(40,52,38,0.25);
$BorderDarkColor: rgba(255, 255, 255, 0.1);
$DarkModeHeaderColor: rgba(0,0,0,0.1);
