/* calendar event */

.month_default_event {
    overflow: hidden;
    border-radius: 15px;
}
.month_default_event_inner {
    background: #636363;
    border-color: #777777;
    color: #fff;
    padding-left: 35px;
    border-radius: 15px;
}

.month_default_event_bar {
    left: 0px;
    width: 30px;

}
.month_default_event_bar_inner {
    background: #888888;
    border-radius: 15px;
    width: 30px;
}

/* context menu icons */
.icon:before {
    position: absolute;
    left: 0px;
    margin-left: 8px;
    margin-top: 3px;
    width: 14px;
    height: 14px;
    content: '';
}

.icon-blue:before { background-color: #3c78d8; }
.icon-green:before { background-color: #6aa84f; }
.icon-yellow:before { background-color: #e69138; }
.icon-red:before { background-color: #cc0000; }
