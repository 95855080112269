/** @format */

@import "./variables.sass";

body.gstc-items-resizing-left * {
  cursor: w-resize !important;
}
body.gstc-items-resizing-right * {
  cursor: e-resize !important;
}
.gstc__chart-timeline-items-row-item.gstc__selected {
  z-index: 2 !important;
}
body.gstc-items-moving * {
  cursor: grabbing !important;
}
.gstc__timeline-chart-items-row-item--moving {
  z-index: 3 !important;
}
body.gstc-scrolling * {
  cursor: grabbing !important;
}

.gstc-wrapper {
  border: 2px solid rgba(90, 140, 35, 0.15);
  overflow: hidden;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.gstc-error {
  background: $redColor;
  color: $whiteColor;
  padding: 10px;
  margin: 10px;
  line-height: 2em;
}
.gstc-error a {
  color: $whiteColor;
}
.gstc-code {
  background: $lightBodyColor;
  color: $darkBodyColor;
  font-family: monospace;
  padding: 4px 7px;
  border-radius: 4px;
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.314);
}
.gstc {
  display: flex;
  overflow: hidden;
  color: #606060;
  font-size: 14px;
  background: $whiteColor;
  line-height: 1.2;
  box-sizing: border-box;
  position: relative;
}
.gstc * {
  box-sizing: border-box;
}
.gstc__helper-center {
  text-align: center;
}
.gstc__helper-dark {
  background: #f9fafb;
}
.gstc__is-selecting .gstc__selected,
.gstc-scrolling .gstc__selected {
  animation-name: none !important;
}
.gstc__list-column-header-resizer--active {
  cursor: ew-resize;
}
.gstc__list-column-header-resizer--active * {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
.gstc__scroll-bar {
  overflow: hidden;
  position: absolute;
  background: rgba(253, 253, 253, 0.471);
  cursor: grab;
}
.gstc__scroll-bar--vertical {
  flex-shrink: 0;
  right: 0px;
}
.gstc__scroll-bar--horizontal {
  bottom: 0px;
}
.gstc__scroll-bar-inner {
  background: rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: absolute;
  touch-action: none;
}
.gstc__scroll-bar-inner:hover,
.gstc__scroll-bar-inner--active {
  background: rgba(0, 0, 0, 0.25);
}
.gstc__list {
  border-collapse: collapse;
  display: flex;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  user-select: none;
  position: relative;
}
.gstc__list > * {
  touch-action: none;
}
.gstc__list-toggle {
  position: absolute;
  user-select: none;
  left: 0px;
  top: 0px;
  overflow: hidden;
  cursor: pointer;
  padding: 14px;
  background: rgba(255, 255, 255, 0.502);
  transition: box-shadow 250ms;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  border-bottom-right-radius: 9px;
}
.gstc__list-toggle:hover {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  background: $whiteColor;
}
.gstc__list-toggle img {
  opacity: 0.5;
}
.gstc__list-column-row-expander {
  display: flex;
  height: var(--height);
  width: calc(var(--expander-padding-width) + var(--expander-size));
  flex-shrink: 0;
}
.gstc__list-column-row-expander-toggle {
  padding-left: var(--expander-padding-width);
  display: block;
  flex-shrink: 0;
  height: var(--height);
  width: calc(var(--expander-size) + var(--expander-padding-width));
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
}
.gstc__list-column-row-expander-toggle img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.gstc__list-column-row-expander-toggle-child {
  cursor: default;
}
.gstc__list-column-row-expander-toggle-open {
  cursor: pointer;
}
.gstc__list-column-row-expander-toggle-closed {
  cursor: pointer;
}
.gstc__list-column {
  width: var(--width);
  position: relative;
}
.gstc__list-column-rows {
  height: var(--height);
  width: var(--width);
  overflow: hidden;
  position: absolute;
}
.gstc__list-column-rows-offset {
  overflow: hidden;
}
.gstc__list-column-row,
.gstc__list-column-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: var(--height);
  line-height: var(--height);
  vertical-align: middle;
  font-size: 14px;
  display: flex;
}
.gstc__list-column-row * {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: text-bottom;
  line-height: var(--height);
}
.gstc__list-column-row {
  border-bottom: 1px solid rgba(237, 241, 242, 0.75);
  border-right: 1px solid rgba(237, 241, 242, 0.75);
  width: var(--width);
}
.gstc__list-column-row-content {
  width: calc(var(--width) - var(--expander-padding-width) - var(--expander-size));
  height: var(--height);
  overflow: hidden;
  padding-left: 6px;
  flex-shrink: 0;
  flex-grow: 1;
}
.gstc__list-column-header {
  background: #f9fafb;
  font-weight: 500;
  color: #707070;
  height: var(--height);
  width: var(--width);
  line-height: var(--height);
  display: flex;
}
.gstc__list-column-header-content {
  vertical-align: middle;
  line-height: calc(var(--height) - 2px);
  width: 100%;
  display: flex;
}
.gstc__list-column-header-resizer {
  display: flex;
  flex-grow: 1;
  background: transparent;
  vertical-align: middle;
  height: var(--height);
  overflow: hidden;
  text-overflow: ellipsis;
}
.gstc__list-column-header-resizer-container {
  overflow: hidden;
  text-overflow: ellipsis;
}
.gstc__list-column-header-resizer-container,
.gstc__list-column-header-resizer-dots {
  display: block;
}
.gstc__list-column-header-resizer-container {
  flex-grow: 1;
  padding-left: 6px;
}
.gstc__list-column-header-resizer-line {
  position: absolute;
  top: 0;
  width: 0px;
  height: 100%;
  border-right: 2px dashed $darkBodyColor;
  display: var(--display);
  left: var(--left);
  z-index: 1000;
}
.gstc__list-column-header-resizer-dots {
  cursor: ew-resize;
  background: transparent;
  padding-top: 12px;
  padding-left: 6px;
  padding-right: 2px;
}
.gstc__list-column-header-resizer-dots-dot {
  background: #e0eff2;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  margin-bottom: 6px;
  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.gstc__list-column-header-resizer-sort-icon {
  margin-left: 4px;
}
.gstc__chart {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  position: relative;
}
.gstc__chart-time-bookmarks {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
}
.gstc__chart-time-bookmark-label {
  position: absolute;
  height: 100%;
  color: $whiteColor;
  font-size: 10px;
}
.gstc__chart-time-bookmark-label-content {
  opacity: 0.6;
  pointer-events: all;
  padding: 2px 4px;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
}
.gstc__chart-time-bookmark-label-content:hover {
  pointer-events: all;
  opacity: 1;
  z-index: 2;
}
.gstc__chart-time-bookmark-line {
  position: absolute;
  height: 100%;
  opacity: 0.6;
}
.gstc__chart-time-bookmark-line-content {
  height: 100%;
}
.gstc__chart-selection {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 119, 192, 0.2);
  border: 2px dashed rgba(0, 119, 192, 0.75);
}
.gstc__chart-calendar {
  background: #f9fafb;
  margin-right: -17px;
  display: flex;
  flex-wrap: wrap;
}
.gstc__chart-calendar-dates {
  overflow: hidden;
  color: #747a81;
  user-select: none;
  display: flex;
  width: 100%;
}
.gstc__chart-calendar-dates--level-main {
  flex-grow: 1;
}
.gstc__chart-calendar-date {
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
  white-space: nowrap;
  border-right: 1px solid rgba(177, 184, 189, 0.5);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.gstc__chart-calendar-date--month {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gstc__chart-calendar-date--day,
.gstc__chart-calendar-date--week {
  text-align: center;
}
.gstc__chart-calendar-date--main-date.gstc-current {
  box-shadow: inset 0px 0px 4px rgba(177, 184, 189, 0.45);
}
.gstc__chart-calendar-date--non-main-date .gstc__chart-calendar-date-content {
  margin-left: 4px;
}
.gstc__chart-calendar-date-content {
  white-space: nowrap;
}
.gstc__chart-calendar-date-content .gstc-dash {
  line-height: 15px;
}
.gstc__chart-calendar-date-content.gstc-date-bold {
  font-weight: 500;
}
.gstc__chart-calendar-date-content.gstc-date-thin {
  font-weight: 300;
}
.gstc__chart-calendar-date-content.gstc-date-normal {
  font-weight: 400 !important;
}
.gstc__chart-calendar-date-content.gstc-date-bottom {
  margin-top: 4px;
}
.gstc__chart-calendar-date-content.gstc-date-left {
  text-align: left;
}
.gstc__chart-calendar-date-content.gstc-date-center {
  text-align: center;
}
.gstc__chart-calendar-date-content.gstc-date-vertical {
  font-size: 14px;
}
.gstc__chart-calendar-date-content.gstc-date-vertical .gstc-date-top {
  margin-top: 2px;
}
.gstc__chart-calendar-date-content.gstc-date-small {
  font-size: 13px;
  font-weight: 300;
  line-height: 1em;
}
.gstc__chart-calendar-date-content.gstc-date-extra-small {
  font-size: 9px;
}
.gstc__chart-calendar-date-content.gstc-date-medium {
  font-size: 16px;
}
.gstc__chart-calendar-date-content.gstc-date-month-level-1 {
  font-size: 16px;
  line-height: 1.6em;
  text-align: center;
}
.gstc__chart-calendar-date-content.gstc-date-big {
  text-align: center;
  font-size: 23px;
  height: var(--calendar-height);
  line-height: var(--calendar-height);
}
.gstc__chart-calendar-date-content--hour {
  text-align: center;
}
.gstc__chart-calendar-date-content--day,
.gstc__chart-calendar-date-content--week {
  font-size: 18px;
  line-height: 1.6em;
  overflow: visible;
}
.gstc__chart-calendar-date-content--day.gstc-date-week-small,
.gstc__chart-calendar-date-content--week.gstc-date-week-small {
  line-height: 1.2em;
  height: calc(var(--calendar-height) - 20px);
  font-size: 14px;
}
.gstc__chart-calendar-date-content--day.current,
.gstc__chart-calendar-date-content--week.current {
  border-right: none;
}
.gstc__chart-calendar-date-content--day.previous,
.gstc__chart-calendar-date-content--week.previous {
  border-right: none;
}
.gstc__chart-timeline {
  overflow: hidden;
  position: absolute;
}
.gstc__chart-timeline-inner {
  overflow: hidden;
}
.gstc__chart-timeline-grid {
  overflow: hidden;
}
.gstc__chart-timeline-grid * {
  user-select: none;
}
.gstc__chart-timeline-grid-row {
  display: flex;
  text-align: center;
  user-select: none;
  pointer-events: all;
  overflow: hidden;
  width: 100%;
}
.gstc__chart-timeline-grid-row-cell {
  pointer-events: all;
  flex-grow: 0;
  flex-shrink: 0;
  vertical-align: middle;
  overflow: hidden;
  border-right: 1px solid rgba(237, 241, 242, 0.75);
  border-bottom: 1px solid rgba(237, 241, 242, 0.75);
  touch-action: none;
  height: 100%;
}
.gstc__chart-timeline-grid-row-cell--selecting {
  background: rgba(0, 119, 192, 0.5) !important;
}
.gstc__chart-timeline-grid-row-cell--selecting:last-child {
  border-right: 1px solid #f00;
}
.gstc__chart-timeline-grid-row-cell--selected {
  background: rgba(0, 119, 192, 0.75) !important;
}
.gstc__chart-timeline-grid-row-cell.current {
  background: rgba(241, 196, 15, 0.1);
}
.gstc__chart-timeline-grid-row-cell--weekend {
  background: #f9fafb;
}
.gstc__chart-timeline-grid-row-cell.gstc__selecting {
  background: rgba(52, 152, 219, 0.627) !important;
}
.gstc__chart-timeline-grid-row-cell.gstc__selected {
  background: $timeLineBlueColor !important;
}
.gstc__chart-timeline-dependency-lines {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
}
.gstc__chart-timeline-dependency-lines-lines {
  pointer-events: none;
  user-select: none;
}
.gstc__chart-timeline-dependency-lines-lines-line {
  position: absolute;
  pointer-events: none;
  user-select: none;
}
.gstc__chart-timeline-dependency-lines-lines-line path {
  stroke: rgba(189, 195, 199, 0.62);
  stroke-width: 2px;
  fill: transparent;
}
.gstc__chart-timeline-dependency-lines-points {
  pointer-events: none;
  user-select: none;
}
.gstc__chart-timeline-dependency-lines-points-point {
  position: absolute;
  pointer-events: none;
  user-select: none;
}
.gstc__chart-timeline-dependency-lines-points-point--left {
  z-index: 3;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: rgba(189, 195, 199, 0.631);
  border: 1px solid rgba(0, 0, 0, 0.278);
  pointer-events: all;
}
.gstc__chart-timeline-dependency-lines-points-point--right {
  z-index: 3;
  width: 100%;
  height: 100%;
  pointer-events: all;
  background: rgba(189, 195, 199, 0.631);
  border: 1px solid rgba(0, 0, 0, 0.278);
  transform: rotate(45deg);
}
.gstc__chart-timeline-dependency-lines * {
  user-select: none;
}
.gstc__chart-timeline-items {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none !important;
  overflow: hidden;
  touch-action: none;
}
.gstc__chart-timeline-items * {
  user-select: none;
}
.gstc__chart-timeline-items-row {
  overflow: hidden;
  position: relative;
  pointer-events: none !important;
  touch-action: none;
}
.gstc__chart-timeline-items-row * {
  touch-action: none;
  pointer-events: all;
}
.gstc__chart-timeline-items-row-item {
  touch-action: none;
  position: absolute;
  user-select: none;
  background: $redColor;
  border-radius: 14px;
  color: $whiteColor;
  line-height: 22px;
  display: flex;
  overflow: hidden;
}
.gstc__chart-timeline-items-row-item--left-cut {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.gstc__chart-timeline-items-row-item--right-cut {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.gstc__chart-timeline-items-row-item-cut {
  height: 100%;
}
.gstc__chart-timeline-items-row-item-cut-image {
  vertical-align: bottom;
  width: 18px;
  opacity: 0.7;
  margin: auto;
}
.gstc__chart-timeline-items-row-item-label {
  touch-action: none;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 4px 10px;
  -webkit-mask-image: linear-gradient(to right, $darkBodyColor 0%, $darkBodyColor 75%, transparent 90%);
  mask-image: linear-gradient(to right, $darkBodyColor 0%, $darkBodyColor 75%, transparent 90%);
}
.gstc__chart-timeline-items-row-item-resizing-handle {
  overflow: hidden;
  z-index: 100;
  pointer-events: all;
  display: flex;
  flex-shrink: 0;
  background-blend-mode: overlay;
  background: rgba(0, 0, 0, 0.06);
}
.gstc__chart-timeline-items-row-item-resizing-handle-content-line {
  width: 1px;
  height: 40%;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: -1px -1px 0px rgba(0, 0, 0, 0.05);
  background-blend-mode: overlay;
  border-radius: 1px;
  margin: auto 1px;
  margin-left: 1px;
}
.gstc__chart-timeline-items-row-item-resizing-handle--right {
  cursor: e-resize;
  padding-left: 5px;
}
.gstc__chart-timeline-items-row-item-resizing-handle--left {
  cursor: w-resize;
  padding-left: 7px;
}
.gstc__chart-timeline-items-row-item-resizing-handle--right-outside {
  border-radius: 14px;
  cursor: e-resize;
  padding-left: 4px;
  background: rgba(0, 0, 0, 0.122);
}
.gstc__chart-timeline-items-row-item-resizing-handle--left-outside {
  border-radius: 14px;
  cursor: w-resize;
  padding-left: 4px;
  background: rgba(0, 0, 0, 0.122);
}
.gstc__chart-timeline-items-row-item-progress-bar {
  background-size: auto auto;
  background-color: rgba(255, 255, 255, 0);
  background-image: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 10px,
    rgba(255, 255, 255, 0.15) 10px,
    rgba(255, 255, 255, 0.15) 20px
  );
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
}
.gstc__chart-timeline-items-row-item.gstc__selecting {
  box-shadow: 0px 0px 1px 2px rgba(0, 119, 192, 0.5);
}
.gstc__chart-timeline-items-row-item.gstc__selected {
  box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  cursor: grab;
}
.gstc__chart-timeline-items-row-item-dependency-line {
  position: absolute;
}
.gstc__chart-timeline-items-row-item-dependency-handle {
  position: absolute;
  border-radius: 100%;
  background: $redColor;
  box-shadow: 0px 4px 4px rgba(199, 44, 28, 0.678);
}
.gstc__item-type.gstc__chart-timeline-items-row-item {
  background: transparent;
  border-radius: 0;
}
.gstc__item-type.gstc__selected {
  box-shadow: none !important;
}
.gstc__item-type.gstc__selected svg {
  filter: drop-shadow(0px 0px 1px $timeLineBlueColor) drop-shadow(0px 0px 2px $timeLineBlueColor);
}
.gstc__item-type .gstc__chart-timeline-items-row-item-resizing-handle--left-outside {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}
.gstc__item-type .gstc__chart-timeline-items-row-item-resizing-handle--right-outside {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}
.gstc__item-type .gstc__chart-timeline-items-row-item-resizing-handle--left {
  border-radius: 0;
}
.gstc__item-type .gstc__chart-timeline-items-row-item-resizing-handle--right {
  border-radius: 0;
}
.gstc__item-type.gstc__chart-timeline-items-row-item-label {
  margin: 4px 16px;
}
.gstc__item-type-progress-line {
  stroke: rgba(255, 255, 255, 0.145);
  stroke-width: 20;
}
.gstc__item-type-content {
  display: flex;
}
.gstc__list-column-rows.gstc__list-column-rows--gstcid-id,
.gstc__list-column-header-resizer-container.gstc__list-column-header-resizer-container--gstcid-id,
.gstc__list-column-header-resizer-container.gstc__list-column-header-resizer-container--gstcid-progress {
  text-align: center;
}

/* DARK MODE */
.dark {
  .gstc {
    background: rgba(255, 255, 255, 0.01);
    color: rgba(255, 255, 255, 0.749);
  }
  .gstc-wrapper {
    border-color: $BorderDarkColor;
  }
  .gstc__chart-calendar {
    background: $DarkModeHeaderColor;
    color: rgba(255, 255, 255, 0.749);
  }
  .gstc__chart-calendar-date {
    border-right: 1px solid rgba(255, 255, 255, 0.102);
    color: rgba(255, 255, 255, 0.631);
  }
  .gstc__list-column-header-resizer-dots-dot {
    background: rgba(255, 255, 255, 0.102);
  }
  .gstc__list-column-header {
    background: $DarkModeHeaderColor;
    color: rgba(255, 255, 255, 0.871);
  }
  .gstc__list-column-row {
    border-bottom: 1px solid rgba($BorderDarkColor, 0.035);
    border-right: 1px solid rgba($BorderDarkColor, 0.035);
    color: #ababb1;
  }
  .gstc__chart-timeline-grid-row-cell {
    border-bottom: 1px solid rgba($BorderDarkColor, 0.035);
    border-right: 1px solid rgba($BorderDarkColor, 0.035);
  }
  .gstc__chart-timeline-grid-row-cell--weekend {
    background: $DarkModeColor;
  }
  .gstc__list-toggle {
    background: rgba(31, 32, 41, 0.188);
  }
  .gstc__scroll-bar {
    background: transparent;
  }
  .gstc__scroll-bar-inner {
    background: rgba(26, 27, 30, 0.741);
  }
  .gstc__list-column-row-expander-toggle img,
  .gstc__list-toggle img {
    filter: brightness(1) invert(1);
  }
}
@-moz-keyframes selected-animation {
  0% {
    background: $timeLineBlueColor;
  }
  100% {
    background: $timeLineBlueColor;
  }
}
@-webkit-keyframes selected-animation {
  0% {
    background: $timeLineBlueColor;
  }
  100% {
    background: $timeLineBlueColor;
  }
}
@-o-keyframes selected-animation {
  0% {
    background: $timeLineBlueColor;
  }
  100% {
    background: $timeLineBlueColor;
  }
}
@keyframes selected-animation {
  0% {
    background: $timeLineBlueColor;
  }
  100% {
    background: $timeLineBlueColor;
  }
}
@-moz-keyframes selected-item-animation {
  0% {
    box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  }
  100% {
    box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  }
}
@-webkit-keyframes selected-item-animation {
  0% {
    box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  }
  100% {
    box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  }
}
@-o-keyframes selected-item-animation {
  0% {
    box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  }
  100% {
    box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  }
}
@keyframes selected-item-animation {
  0% {
    box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  }
  100% {
    box-shadow: 0px 0px 1px 2px $timeLineBlueColor;
  }
}
