/** @format */

@import "./variables.sass";

:root {
  --box_space: 24px 24px 16px;
}

body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.light {
    background-color: $lightBodyColor;
  }

  &.dark {
    background-color: $darkBodyColor;

    ::-webkit-scrollbar {
      width: 15px;
    }

    ::-webkit-scrollbar-track {
      background: #7a7e83;
    }

    ::-webkit-scrollbar-thumb {
      background: #4b5768;
    }
  }
}

.dark::-webkit-scrollbar {
  width: 15px;
}

.dark::-webkit-scrollbar-track {
  background: #7a7e83;
}

.dark::-webkit-scrollbar-thumb {
  background: #4b5768;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input[type="file"] {
  padding: 10px;
  background: $primaryColorLight;
  color: $whiteColor;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

p:empty {
  display: none;
}

// .MuiDrawer-paper {
//   width: 30%;
//   .MuiListItem-button.Mui-selected {
//     .MuiListItemIcon-root {
//       color: $primaryColorLight;
//     }
//     // &:hover {
//     //     .MuiListItemIcon-root {
//     //         color: $whiteColor;
//     //     }
//     // }
//   }
// }

.dark {
  .MuiDrawer-paper {
    .MuiListItem-button:not(.MuiListItem-button.Mui-selected) {
      .MuiListItemIcon-root {
        color: rgba($whiteColor, 0.3);
      }
    }
  }
}

.chart_box,
.des_blog {
  border-radius: $borderRadius;
  overflow: hidden;
  box-shadow: $boxShadow;
  border: 2px solid rgba(0, 0, 0, 0.06);
  background: rgba($whiteColor, 0.3);
  height: 100%;
}

.des_growth_chart_sec {
  margin: 10px 0;

  .chart_box {
    padding: var(--box_space);

    .MuiTypography-root:not(.MuiTypography-body1) {
      font-weight: 500;
    }
  }
}

.dark {
  .chart_box,
  .des_blog {
    border-color: rgba($DarkBackgroundSelect, 0.5);
    background-color: $DarkBackground;
  }
}

.des_blog {
  padding: var(--box_space);

  .MuiTypography-root:not(.MuiTypography-body1) {
    font-weight: 500;
  }
}

.des_blog {
  .MuiTypography-gutterBottom {
    margin-bottom: 1em;
  }
}

.des_blog_list {
  ul {
    --list-space: 10px;

    li {
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: calc(var(--list-space) * 1.2);

      .des_blog_list_img {
        margin-right: 1.5em;

        a {
          display: flex;

          img {
            width: 6em;
            height: 6em;
            object-fit: cover;
            object-position: center;
            border-radius: $borderRadius;
            box-shadow: $boxShadow;
            border: 2px solid rgba($primaryColorLight, 0.3);
          }
        }
      }

      .des_blog_list_text {
        a {
          color: $primaryColorLight;
          text-decoration: none;
          display: inline-block;
          margin-bottom: 0.3em;

          &:hover {
            text-decoration: underline;
          }
        }

        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: #b8b8b8;
        }
      }
    }
  }
}

.MuiCollapse-wrapper {
  .MuiTable-root {
    //border: none;

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:last-child {
          border: none;

          .MuiTableCell-root {
            //border: none;
          }
        }
      }
    }
  }
}

.MuiTable-root {
  .MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        padding-left: 8px !important;
        padding-right: 8px !important;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        padding-left: 8px !important;
        padding-right: 8px !important;
      }
    }
  }
}

.MuiTable-root {
  .MuiTableCell-head {
    .MuiButton-root {
      font-weight: bold !important;
    }
  }

  .MuiTableCell-body {
    font-size: 13px;
  }

  .MuiTableCell-root {
    .MuiSvgIcon-root {
      vertical-align: sub;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  }

  &[aria-label="collapsible table"] {
    border-bottom-width: 2px;
  }

  .MuiInputBase-input {
    padding: 4px !important;
    font-size: 13px;
    width: 100%;
  }

  // .estimation-costing_table {
  //   .MuiTableCell-root {
  //     font-size: 16px;
  //     padding-top: 10px;
  //     padding-bottom: 10px;
  //   }
  // }
  .MuiTableCell-roo:last-child {
    padding-bottom: 16px !important;
  }

  .estimation-option_row {
    .MuiTableCell-body {
      padding: 0px 5px;
    }

    .MuiTableContainer-root {
      padding-bottom: 16px;

      .MuiTableCell-body {
        // border: none;
      }

      .estimate-option_table {
        .MuiTableCell-head {
          font-size: 13px;
        }

        .MuiTableCell-body {
          font-size: 12px;
        }
      }
    }
  }
}

.link {
  color: $primaryColorLight;
}

.MuiButton-root.Mui-disabled {
  border-color: #ccc;
}

.MuiCard-root {
  margin-bottom: 10px;

  .MuiCardContent-root:last-child {
    padding-bottom: 16px !important;
  }
}

.MuiCheckbox-root .MuiSvgIcon-root {
  padding: 2px;
}

.MuiLinearProgress-root {
  margin: 10px 0;
}

.header_icon {
  .MuiIconButton-sizeSmall {
    padding: 5px;
  }
}

.MuiTable-root {
  // .muitable_cell_title_type {
  //   min-width: 155px;
  // }

  // .muitable_cell_title_uom {
  //   min-width: 65px;
  // }
  // #mui-component-select-type {
  //   width: 123px;
  // }
  // #muitable_cell_title_qlt {
  //   width: 55px;
  // }
}

.MuiTableFooter-root {
  .MuiTablePagination-root {
    .MuiSelect-select {
      padding-right: 10px !important;
    }

    .MuiSvgIcon-root {
      right: -10px !important;
    }
  }
}

svg#hover2:hover {
  color: #fff !important;
}

button#hover1:hover {
  background: #626119 !important;
  cursor: pointer !important;
}

.MuiSelect-nativeInput {
  left: 0;
  width: 100%;
  bottom: 0;
  opacity: 0;
  position: fixed !important;
  pointer-events: none;
}

// media query applied here

@media only screen and (min-width: 375px) {
  .estimate-details-title {
    font-size: 10px;
  }

  .estimate-details-title-main {
    font-size: 12px;
  }
}

// .MuiSvgIcon-colorAction {
//     color:#626119!important;
// }
// .makeStyles-Drawerroot-509 {
//     width: 100%;
// }
// .MuiButton-outlinedPrimary {
//     color: #5A8C24 !important;
//     border: 1px solid #5A8C24 !important;
//     background: #F5FBEF;
//     font-weight: bold !important;
//     padding: 7px 9px !important;
// }
// .MuiPaper-root.css-1qac5w0.MuiPaper-elevation1.MuiPaper-rounded {
//     padding: 29px 0;
// }
// .makeStyles-stepper-190 {
//     width: 30%;
//     align-items: center !important;
//     margin: 25px auto !important;
// }
// textarea.textarea {
//     width: 77%;
//     height: 100px;
//     padding: 8px 8px;
//     font-size: 16px;
// }
// h3.width {
//     // width: 27%;
// }
//     .MuiStepper-horizontal {
//         border: 1px solid #95bb6c;
//         align-items: center;
//         border-radius: 60px;
//         margin-bottom: 30px;
//         flex-direction: row;
//         width: 33%;
//         position: relative;
//         right: 15%;
//     }
// p.work {
//     flex: 2 1;
// background: #ffff;
// padding: 0.7rem;
// text-align: left;
// border-radius: 7px;
// width: 45%;
// border: 1px solid #ccc;

// }

// .makeStyles-content-15{
//     height: auto !important;
//     overflow: hidden !important;
// }
// .MuiSelect-icon {
//   top: calc(50% - 12px);
//   color: rgba(0, 0, 0, 0.54);
//   right: 0;
//   position: relative !important;
//   pointer-events: none;
// }
// input#description {
//   width: 171px;
// }
// input#unicost-width {
//   width: 60px;
//   text-align: right !important;
// }
// input#right {
//   text-align: right;
// }
// input#qty-width {
//   // width: 43px;
//   text-align: right;
// }
// p.font {
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
// }
// .MuiFormControl-root.MuiTextField-root {
//   width: 100%;
//   display: flex !important;
// }
//     .MuiBox-root.MuiBox-root-25 {
//         width: 55%;
//         align-items: center;
//         margin: 0 auto;
//         height: 259px;
//     }
//     .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.MuiGrid-grid-md-12.css-jay7o6-MuiGrid-root {
//         background: whitesmoke;
//     }
// @media (min-width: 1200px) {
//   .des_blog_list {
//     --list-space: 15px;
//     ul {
//       margin: 0 calc(var(--list-space) * -1);
//       li {
//         display: inline-flex;
//         margin-bottom: calc(var(--list-space) * 1.2);
//         flex: 0 0 50%;
//         max-width: 50%;
//         padding: 0 var(--list-space);
//       }
//     }
//   }
// }

// @media (min-width: 320px) and (max-width: 767px) {
//   .MuiGrid-root {
//     .MuiGrid-item {
//       .MuiGrid-grid-xs-10 {
//         max-width: 100%;
//         flex-basis: 100%;
//       }
//       .MuiGrid-grid-xs-6 {
//         max-width: 100%;
//         flex-basis: 100%;
//       }
//       .MuiGrid-grid-xs-3 {
//         max-width: 100%;
//       }
//     }
//   }
//   .makeStyles-Drawerroot-24 {
//     width: auto !important;
//   }

//   .MuiDrawer-root {
//     .MuiDrawer-modal {
//       z-index: 1300;
//     }
//     .MuiBox-root-144 {
//       margin: 12px;
//     }
//   }
//   .makeStyles-Drawerroot-173 {
//     width: 100% !important;
//   }
// }
// @media (min-width: 768px) and (max-width: 991px) {
//   .MuiGrid-root {
//     .MuiGrid-item {
//       .MuiGrid-grid-xs-10 {
//         max-width: 100%;
//         flex-basis: 100%;
//       }
//       .MuiGrid-grid-xs-3 {
//         position: relative;
//         right: 100px;
//       }
//     }
//   }
// }
