/* @font-face {
	font-family: 'daypiloticons';
	src:url('fonts/daypiloticons.eot?cyz0y3');
	src:url('fonts/daypiloticons.eot?cyz0y3#iefix') format('embedded-opentype'),
		url('fonts/daypiloticons.ttf?cyz0y3') format('truetype'),
		url('fonts/daypiloticons.woff?cyz0y3') format('woff'),
		url('fonts/daypiloticons.svg?cyz0y3#daypiloticons') format('svg');
	font-weight: normal;
	font-style: normal;
} */

[class^="icon-"], [class*=" icon-"] {
	font-family: 'daypiloticons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-edit:before {
	content: "\e908";
}
.icon-triangle-down:before {
	content: "\e907";
}
.icon-info:before {
	content: "\e905";
}
.icon-move:before {
	content: "\e900";
}
.icon-menu:before {
	content: "\e903";
}
.icon-bubble:before {
	content: "\e904";
}
.icon-resize-left:before {
	content: "\e901";
}
.icon-resize-right:before {
	content: "\e902";
}
.icon-menu2:before {
	content: "\e906";
}

